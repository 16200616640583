import React from "react";
import "./testimonial.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Testi1 from "../assets/images/daniel.webp";
import Testi2 from "../assets/images/sophia.webp";
import Testi3 from "../assets/images/benjamin.webp";
import Testi4 from "../assets/images/emily.webp";
import Testi5 from "../assets/images/lucas.jpg";
import Testi6 from "../assets/images/isabella.webp";

function Testimonial() {
  return (
    <section className="testimonialsec">
      <Container>
      <div class="circle-bg"></div>
        <Row>
          <Col>
            <h2 className="testimonialsech2">Experience It Yourself</h2>
            <p><strong>See what our customers have to say and why they love us.</strong></p>
            <div class="reviews-list">
                <div class="review boxed">
                  <div class="title">A Must-Have for Writers</div>
                  <div class="testimonial">
                    StealthGPT takes away the stigma around AI writing assistance. It allows me to utilize the advantages of AI without any drawbacks. Highly recommend it to all writers out there!
                  </div>
                  <div class="infobx">
                    <div class="source">
                      <div class="source-name">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg>
                        <span>LinkedIn</span>
                      </div>
                      <div class="rating">
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                      </div>
                    </div>
                    <div class="author flex vertical-align-center">
                      <span class="name">Daniel</span>
                      <div class="thumbnail">
                        <img src={Testi1} />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="review boxed">
                  <div class="title">Impressive and Reliable</div>
                  <div class="testimonial">
                    With StealthGPT, I no longer worry about using AI to aid my writing. It's the best in the market for making AI-written text undetectable. It's a must-have tool!
                  </div>
                  <div class="infobx">
                    <div class="source">
                      <div class="source-name">
                        <svg class="" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 186.69 190.5"><g transform="translate(1184.583 765.171)"><path clip-path="none" mask="none" d="M-1089.333-687.239v36.888h51.262c-2.251 11.863-9.006 21.908-19.137 28.662l30.913 23.986c18.011-16.625 28.402-41.044 28.402-70.052 0-6.754-.606-13.249-1.732-19.483z" fill="#4285f4"></path><path clip-path="none" mask="none" d="M-1142.714-651.791l-6.972 5.337-24.679 19.223h0c15.673 31.086 47.796 52.561 85.03 52.561 25.717 0 47.278-8.486 63.038-23.033l-30.913-23.986c-8.486 5.715-19.31 9.179-32.125 9.179-24.765 0-45.806-16.712-53.34-39.226z" fill="#34a853"></path><path clip-path="none" mask="none" d="M-1174.365-712.61c-6.494 12.815-10.217 27.276-10.217 42.689s3.723 29.874 10.217 42.689c0 .086 31.693-24.592 31.693-24.592-1.905-5.715-3.031-11.776-3.031-18.098s1.126-12.383 3.031-18.098z" fill="#fbbc05"></path><path d="M-1089.333-727.244c14.028 0 26.497 4.849 36.455 14.201l27.276-27.276c-16.539-15.413-38.013-24.852-63.731-24.852-37.234 0-69.359 21.388-85.032 52.561l31.692 24.592c7.533-22.514 28.575-39.226 53.34-39.226z" fill="#ea4335" clip-path="none" mask="none"></path></g></svg>
                        <span>Google</span>
                      </div>
                      <div class="rating">
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        <svg stroke="currentColor" fill="#2c2c2c" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-zinc-700" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                      </div>
                    </div>
                    <div class="author flex vertical-align-center">
                      <span class="name">Sophia</span>
                      <div class="thumbnail">
                        <img src={Testi2} />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="review boxed">
                  <div class="title">Exceptional Innovation</div>
                  <div class="testimonial">
                    StealthGPT is an innovative tool that is much needed in today's AI-driven world. I'm impressed with its functionality and ease of use. Kudos to the team!
                  </div>
                  <div class="infobx">
                    <div class="source">
                      <div class="source-name">
                        <svg class="" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 186.69 190.5"><g transform="translate(1184.583 765.171)"><path clip-path="none" mask="none" d="M-1089.333-687.239v36.888h51.262c-2.251 11.863-9.006 21.908-19.137 28.662l30.913 23.986c18.011-16.625 28.402-41.044 28.402-70.052 0-6.754-.606-13.249-1.732-19.483z" fill="#4285f4"></path><path clip-path="none" mask="none" d="M-1142.714-651.791l-6.972 5.337-24.679 19.223h0c15.673 31.086 47.796 52.561 85.03 52.561 25.717 0 47.278-8.486 63.038-23.033l-30.913-23.986c-8.486 5.715-19.31 9.179-32.125 9.179-24.765 0-45.806-16.712-53.34-39.226z" fill="#34a853"></path><path clip-path="none" mask="none" d="M-1174.365-712.61c-6.494 12.815-10.217 27.276-10.217 42.689s3.723 29.874 10.217 42.689c0 .086 31.693-24.592 31.693-24.592-1.905-5.715-3.031-11.776-3.031-18.098s1.126-12.383 3.031-18.098z" fill="#fbbc05"></path><path d="M-1089.333-727.244c14.028 0 26.497 4.849 36.455 14.201l27.276-27.276c-16.539-15.413-38.013-24.852-63.731-24.852-37.234 0-69.359 21.388-85.032 52.561l31.692 24.592c7.533-22.514 28.575-39.226 53.34-39.226z" fill="#ea4335" clip-path="none" mask="none"></path></g></svg>
                        <span>Google</span>
                      </div>
                      <div class="rating">
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                      </div>
                    </div>
                    <div class="author flex vertical-align-center">
                      <span class="name">Benjamin</span>
                      <div class="thumbnail">
                        <img src={Testi3} />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="review boxed">
                  <div class="title">Essential for Students</div>
                  <div class="testimonial">
                   This is a great tool for students like me who rely on AI for help with writing assignments. Thanks to StealthGPT, I can use AI without any worry!
                  </div>
                  <div class="infobx">
                    <div class="source">
                      <div class="source-name">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg>
                        <span>LinkedIn</span>
                      </div>
                      <div class="rating">
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        <svg stroke="currentColor" fill="#2c2c2c" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-zinc-700" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        <svg stroke="currentColor" fill="#2c2c2c" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-zinc-700" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                      </div>
                    </div>
                    <div class="author flex vertical-align-center">
                      <span class="name">Emily</span>
                      <div class="thumbnail">
                        <img src={Testi4} />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="review boxed">
                  <div class="title">Amazing Tool for AI writing</div>
                  <div class="testimonial">
                    StealthGPT is an amazing tool that ensures my AI-written work is undetectable. It's been a great help in improving my productivity and efficiency.
                  </div>
                  <div class="infobx">
                    <div class="source">
                      <div class="source-name">
                        <svg class="" xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 186.69 190.5"><g transform="translate(1184.583 765.171)"><path clip-path="none" mask="none" d="M-1089.333-687.239v36.888h51.262c-2.251 11.863-9.006 21.908-19.137 28.662l30.913 23.986c18.011-16.625 28.402-41.044 28.402-70.052 0-6.754-.606-13.249-1.732-19.483z" fill="#4285f4"></path><path clip-path="none" mask="none" d="M-1142.714-651.791l-6.972 5.337-24.679 19.223h0c15.673 31.086 47.796 52.561 85.03 52.561 25.717 0 47.278-8.486 63.038-23.033l-30.913-23.986c-8.486 5.715-19.31 9.179-32.125 9.179-24.765 0-45.806-16.712-53.34-39.226z" fill="#34a853"></path><path clip-path="none" mask="none" d="M-1174.365-712.61c-6.494 12.815-10.217 27.276-10.217 42.689s3.723 29.874 10.217 42.689c0 .086 31.693-24.592 31.693-24.592-1.905-5.715-3.031-11.776-3.031-18.098s1.126-12.383 3.031-18.098z" fill="#fbbc05"></path><path d="M-1089.333-727.244c14.028 0 26.497 4.849 36.455 14.201l27.276-27.276c-16.539-15.413-38.013-24.852-63.731-24.852-37.234 0-69.359 21.388-85.032 52.561l31.692 24.592c7.533-22.514 28.575-39.226 53.34-39.226z" fill="#ea4335" clip-path="none" mask="none"></path></g></svg>
                        <span>Google</span>
                      </div>
                      <div class="rating">
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        <svg stroke="currentColor" fill="#2c2c2c" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-zinc-700" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                      </div>
                    </div>
                    <div class="author flex vertical-align-center">
                      <span class="name">Lucas</span>
                      <div class="thumbnail">
                        <img src={Testi5} />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="review boxed">
                  <div class="title">Life Changing</div>
                  <div class="testimonial">
                    As a journalist, sometimes I need to crank out articles quickly. StealthGPT allows me to leverage AI without it being noticeable. Truly life-changing.
                  </div>
                  <div class="infobx">
                    <div class="source">
                      <div class="source-name">
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="w-4"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg>
                        <span>LinkedIn</span>
                      </div>
                      <div class="rating">
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-4 w-4 text-indigo-500" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                      </div>
                    </div>
                    <div class="author flex vertical-align-center">
                      <span class="name">Isabella</span>
                      <div class="thumbnail">
                        <img src={Testi6} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Testimonial;
