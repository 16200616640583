import React from "react";
import "./faq.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Accordion from "react-bootstrap/Accordion";

function Faqsection() {
  return (
    <section className="faqsection">
      <Container>
      {/* <div class="circle-bg"></div> */}
        <Row>
          <Col className="accorddol">
            <h2 className="faqhead">Frequently Asked Questions</h2>
            <p className="faqpara">
              Our FAQ covers some of the most commonly asked questions about the
              most Undetectable AI tool.
            </p>
            <a href="#" className="faqbtn">
              Start Writing Now
            </a>
          </Col>
          <Col xs={7}>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  What tools does StealthGPT offer?
                </Accordion.Header>
                <Accordion.Body>
                  StealthGPT provides four powerful tools: Stealth Tool for
                  discrete responses, Rephraser Tool for rewording content,
                  Stealth Tutor for short-answer expertise, and Stealth Essay
                  for crafting exceptional essays. Stay tuned for more!
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  Is StealthGPT multi-lingual?
                </Accordion.Header>
                <Accordion.Body>
                  Indeed! We cater to most commonly spoken languages.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  Is "unlimited uses" truly unlimited?
                </Accordion.Header>
                <Accordion.Body>
                  Virtually! You'd have to recreate every textbook for your
                  classes each month to run out of uses.
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>Is the content original?</Accordion.Header>
                <Accordion.Body>Yes</Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  Is StealthGPT's AI writer undetectable?
                </Accordion.Header>
                <Accordion.Body>
                  Yes. We check all outputs against the top AI detectors, and if
                  something does cause an output to be detected, we give you a
                  warning and advise to use a different prompt.<br/><br/> If our system
                  gives you a high stealth score, you can be confident that your
                  output is undetectable. If you somehow get detected with a
                  high stealth score, please contact us and we will provide a
                  full refund.
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Faqsection;
