import React from "react";
import "./adbanner.css";
import Container from "react-bootstrap/Container";
import Adbanimg from "../assets/images/footer-ad-banner.webp";

function Adbanner() {
  return (
    <section className="ad-banner">
      <Container>
      <div className="ad-banner boxed brand-accent flex">
        <div className="text width-50">
          <h2>
            Write Faster, Better Marketing, with Generative AI
          </h2>
          <div className="description">
            Start for free and boost your brand with StealthGPT
          </div>
          <div className="cta-button">
            <button type="button" className="dark-accent">Get Started. It’s FREE</button>
          </div>
          <div className="sm-text">No credit card required • <a href="#">See plans &amp; pricing</a> →</div>
        </div>
        <div className="thumbnail width-50">
          <img src={Adbanimg} alt="" />
        </div>
        </div>
      </Container>
    </section>
  );
}

export default Adbanner;
