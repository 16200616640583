import React from "react";
import "./aisection.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Aisection() {
  return (
    <section className="aisection">
      <Container>
        <Row>
          <Col>
            <h2 className="aisech2">Undetectable <span>AI</span></h2>
            <p>Ever since ChatGPT went viral in late 2022 there was a massive effort to detect AI generated content. StealthGPT, the first project to create Undetectable AI content, began in early 2023 and since then others have joined in the fight to provide similar kinds of content. However what makes StealthGPT different from the rest of our competitors is not just that we were the first ones to make such a product. We pride ourselves on how powerful the StealthGPT engine is. Consistently scoring well above our competitors in undetectability, giving the most affordable rates to our users, and having the most tools available. As a cherry on top we are also the only service to date that openly gives our users the option to upgrade to the most powerful GPT engine available.</p>
            <div class="table boxed space-0 bottom-space-40">
                  <table>
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th>Similar AI Services</th>
                        <th>StealthGPT</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>100% Undetectable</td>
                        <td>
                          <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#2d2d2d" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#2d2d2d" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#2d2d2d" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#2d2d2d" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        </td>
                        <td>
                          <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        </td>
                      </tr>
                      <tr>
                        <td>Very Affordable</td>
                        <td>
                          <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#2d2d2d" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#2d2d2d" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#2d2d2d" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#2d2d2d" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        </td>
                        <td>
                          <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        </td>
                      </tr>
                      <tr>
                        <td>Multiple Tools</td>
                        <td>
                          <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#2d2d2d" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#2d2d2d" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#2d2d2d" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#2d2d2d" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        </td>
                        <td>
                          <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        </td>
                      </tr>
                      <tr>
                        <td>Stealth Samurai Access</td>
                        <td>
                          <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#2d2d2d" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#2d2d2d" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#2d2d2d" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#2d2d2d" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        </td>
                        <td>
                          <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                          <svg stroke="currentColor" fill="#473dd5" stroke-width="0" viewBox="0 0 16 16" class="h-3 w-3 text-indigo-500 sm:h-4 sm:w-4" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"></path></svg>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
            <div className="aisecbtd">
              <a href="#" className="aisecbtn">
                Sign Up
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Aisection;
