import React from "react";
import "./about.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Ninjaimg from "../assets/images/ninja.webp";

function Aboutsec() {
  return (
    <section className="aboutsection">
      <Container>
        <Row>
          <Col>
            <div className="aboutidv">
              <img src={Ninjaimg} alt="" className="aboutimg" />
            </div>
          </Col>
          <Col>
            <h2 className="abouth2">
              <span>100% Undetectable</span>
              <br />
              AI Guaranteed!
            </h2>
            <p>
              StealthGPT is the first AI-powered tool dedicated to generating
              Undetectable AI Content. Since AI’s massive adoption there has
              been a widespread effort to make ai content detectable. Many AI
              detectors such as GPTZero, TurnitIn, Winston AI, and others are
              propping up. Now though, after many months of engineering,
              StealthGPT has perfected the art of utilizing AI to make
              undetectable ai generated content. It knows exactly what AI
              detectors are looking for and understands their methodology so it
              can avoid detection with pinpoint accuracy. Not only does it
              bypass the AI detectors but it also produces content in such a way
              that it is uniquely different each time a response is generated,
              thereby also avoiding plagiarism issues. Tens of thousands of
              users are making use of StealthGPT today.
            </p>
            <a href="#" className="aboutbtn">
              Join Them
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Aboutsec;
