import React from "react";
import "./topbar.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faArrowRight } from '@fortawesome/free-solid-svg-icons'

function Topbarsec() {
  return (
    <section className="topbarsec">
    <Container>
      <Row>
        <Col>
        <p className="topbartxt">StealthGPT is the ONLY AI platform that beats Turnitin AI-Detection <FontAwesomeIcon icon={faArrowRight} /></p>
        </Col>
      </Row>
    </Container>
    </section>
  );
}

export default Topbarsec;
