import React from "react";
import "./banner.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Bannerimg from "../assets/images/stealth-banner-background.webp";

function Bannersection() {
  return (
    <section className="bannersec">
        <h2><span>Undetectable AI</span><br />For All Your Daily Needs</h2>
        <p>From emails, to blogs, to papers – Our Undetectable AI, plagiarism-free AI tool<br/> allows you to get more done, 10x faster so you can concentrate on what matters…</p>
        <div className="videodiv"><a href="#" className="videobtn"><FontAwesomeIcon icon={faPlay} /></a></div>
        <div className="bannerbtndiv">
        <ul className="bannerbtn">
            <li><a href="#">Get Chrome Extension</a></li>
            <li><a href="#" className="bansign">Sign Up</a></li>
        </ul>
        </div>
        <img src={Bannerimg} alt="" className="bannerimg"/>
    </section>
  );
}

export default Bannersection;
