import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Topbarsec from './components/topbar/topbar';
import Navbar from './components/navbar/navbar';
import Banner from './components/banner/banner';
import Aboutus from './components/about-us/about';
import Brandsec from './components/brands/brands';
import Aisection from './components/ai-section/aisection';
import Pricingsec from './components/pricing/pricing';
import Testimonial from './components/testimonials/testimonial';
import Faqsec from './components/faq-section/faq';
import Adbanner from './components/advertisement/adbanner';
import Footersec from './components/footer/footer';

const App = () => {
  return ( 
    <div>
      <Topbarsec />
      <Navbar />
      <Banner />
      <Aboutus />
      <Brandsec />
      <Aisection />
      <Pricingsec />
      <Testimonial />
      <Faqsec />
      <Adbanner />
      <Footersec />
    </div>
  )
}

export default App
