import React from "react";
import "./pricing.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function Pricingsec() {
  return (
    <section className="pricingsec">
      <div class="circle-bg"></div>
      <Container>
        <Row>
          <Col>
            <h2 className="pricingh2">
              Unlock <span>Unlimited </span>Stealth
            </h2>
            <div className="tabbtn">
              <Tabs
                defaultActiveKey="monthly"
                transition={false}
                id="noanim-tab-example"
                className="mb-3"
              >
                <Tab eventKey="monthly" title="Monthly">
                  <div class="plan-list flex vertical-align-center">
                    <div class="plan boxed samesame">
                      <div class="plan-name text-white">Essential</div>
                      <div class="price">$14.99</div>
                      <div class="discounted-price">$18.74</div>
                      <div class="label">Billed Monthly</div>
                      <div class="excerpt">
                        For the creator who is just starting out and needs to
                        get their content out there.
                        <div class="text-white">
                          Great for new creators, small businesses, and content
                          creators.
                        </div>
                      </div>
                      <div class="words-amount">100,000</div>
                      <div class="words-label">Words Per Month</div>
                      <ul class="check-list">
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Instant Response Time</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Beats Turnitin, GPTZero, And More!</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Access To The Infinity Stealth Engine</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>
                            Access To Proprietary Standard Stealth Engine
                          </span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>
                            Process Up To 750 Words On The Infinity Engine
                          </span>
                        </li>
                      </ul>
                      <div class="divider"></div>
                      <ul class="un-check-list">
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#212121"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Increased Coherency On Infinity Engine</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#212121"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Beats Winston AI, CopyLeaks, And More!</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#212121"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>
                            Access To The Most Power Stealth Engine: Samuari
                          </span>
                        </li>
                      </ul>
                      <div class="upgrade flex">
                        <button
                          type="button"
                          role="checkbox"
                          aria-checked="true"
                          data-state="checked"
                          value="on"
                        ></button>
                        <label>
                          Upgrade to our Stealth Samurai Engine for just
                          $4.99/month.
                        </label>
                      </div>
                      <div class="action-button">
                        <button type="button">Get Started</button>
                      </div>
                    </div>
                    <div class="plan boxed recommended">
                      <div class="plan-name text-white">Pro</div>
                      <div class="price">$19.99</div>
                      <div class="discounted-price">$24.99</div>
                      <div class="label">Billed Monthly</div>
                      <div class="excerpt">
                        For the professional who is already using AI to produce
                        a high volume of content, and needs peace of mind that
                        their writings wont be penalized for AI.
                        <div class="text-white">
                          Great for SEO specialists, blog editors, and growing
                          creators.
                        </div>
                      </div>
                      <div class="words-amount">500,000</div>
                      <div class="words-label">Words Per Month</div>
                      <ul class="check-list">
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Instant Response Time</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Beats Turnitin, GPTZero, And More!</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Access To The Infinity Stealth Engine</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>
                            Access To Proprietary Standard Stealth Engine
                          </span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>
                            Process Up To 1,500 Words On The Infinity Engine
                          </span>
                        </li>
                      </ul>
                      <div class="divider"></div>
                      <ul class="un-check-list">
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#212121"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Increased Coherency On Infinity Engine</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#212121"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Beats Winston AI, CopyLeaks, And More!</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#212121"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>
                            Access To The Most Power Stealth Engine: Samuari
                          </span>
                        </li>
                      </ul>
                      <div class="upgrade flex">
                        <button
                          type="button"
                          role="checkbox"
                          aria-checked="true"
                          data-state="checked"
                          value="on"
                        ></button>
                        <label>
                          Upgrade to our Stealth Samurai Engine for just
                          $4.99/month.
                        </label>
                      </div>
                      <div class="action-button">
                        <button type="button">Get Started</button>
                      </div>
                    </div>
                    <div class="plan boxed samesame">
                      <div class="plan-name text-white">Exclusive</div>
                      <div class="price">$29.99</div>
                      <div class="discounted-price">$37.49</div>
                      <div class="label">Billed Monthly</div>
                      <div class="excerpt">
                        For the professional who is already using AI to produce
                        a high volume of content, and needs peace of mind that
                        their writings wont be penalized for AI.
                        <div class="text-white">
                          Great for new creators, small businesses, and content
                          creators.
                        </div>
                      </div>
                      <div class="words-amount">1,000,000</div>
                      <div class="words-label">Words Per Month</div>
                      <ul class="check-list">
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Instant Response Time</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Beats Turnitin, GPTZero, And More!</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Access To The Infinity Stealth Engine</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>
                            Access To Proprietary Standard Stealth Engine
                          </span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>
                            Process Up To 2,000 Words On The Infinity Engine
                          </span>
                        </li>
                      </ul>
                      <div class="divider"></div>
                      <ul class="un-check-list">
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#212121"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Increased Coherency On Infinity Engine</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#212121"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Beats Winston AI, CopyLeaks, And More!</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#212121"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>
                            Access To The Most Power Stealth Engine: Samuari
                          </span>
                        </li>
                      </ul>
                      <div class="upgrade flex">
                        <button
                          type="button"
                          role="checkbox"
                          aria-checked="true"
                          data-state="checked"
                          value="on"
                        ></button>
                        <label>
                          Upgrade to our Stealth Samurai Engine for just
                          $4.99/month.
                        </label>
                      </div>
                      <div class="action-button">
                        <button type="button">Get Started</button>
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="annual" title="Annual">
                <div class="plan-list flex vertical-align-center">
                    <div class="plan boxed samesame">
                      <div class="plan-name text-white">Essential</div>
                      <div class="price">$99.99</div>
                      <div class="discounted-price">$18.74</div>
                      <div class="label">Billed Monthly</div>
                      <div class="excerpt">
                        For the creator who is just starting out and needs to
                        get their content out there.
                        <div class="text-white">
                          Great for new creators, small businesses, and content
                          creators.
                        </div>
                      </div>
                      <div class="words-amount">100,000</div>
                      <div class="words-label">Words Per Month</div>
                      <ul class="check-list">
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Instant Response Time</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Beats Turnitin, GPTZero, And More!</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Access To The Infinity Stealth Engine</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>
                            Access To Proprietary Standard Stealth Engine
                          </span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>
                            Process Up To 750 Words On The Infinity Engine
                          </span>
                        </li>
                      </ul>
                      <div class="divider"></div>
                      <ul class="un-check-list">
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#212121"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Increased Coherency On Infinity Engine</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#212121"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Beats Winston AI, CopyLeaks, And More!</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#212121"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>
                            Access To The Most Power Stealth Engine: Samuari
                          </span>
                        </li>
                      </ul>
                      <div class="upgrade flex">
                        <button
                          type="button"
                          role="checkbox"
                          aria-checked="true"
                          data-state="checked"
                          value="on"
                        ></button>
                        <label>
                          Upgrade to our Stealth Samurai Engine for just
                          $4.99/month.
                        </label>
                      </div>
                      <div class="action-button">
                        <button type="button">Get Started</button>
                      </div>
                    </div>
                    <div class="plan boxed recommended">
                      <div class="plan-name text-white">Pro</div>
                      <div class="price">$199.99</div>
                      <div class="discounted-price">$24.99</div>
                      <div class="label">Billed Monthly</div>
                      <div class="excerpt">
                        For the professional who is already using AI to produce
                        a high volume of content, and needs peace of mind that
                        their writings wont be penalized for AI.
                        <div class="text-white">
                          Great for SEO specialists, blog editors, and growing
                          creators.
                        </div>
                      </div>
                      <div class="words-amount">500,000</div>
                      <div class="words-label">Words Per Month</div>
                      <ul class="check-list">
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Instant Response Time</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Beats Turnitin, GPTZero, And More!</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Access To The Infinity Stealth Engine</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>
                            Access To Proprietary Standard Stealth Engine
                          </span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>
                            Process Up To 1,500 Words On The Infinity Engine
                          </span>
                        </li>
                      </ul>
                      <div class="divider"></div>
                      <ul class="un-check-list">
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#212121"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Increased Coherency On Infinity Engine</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#212121"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Beats Winston AI, CopyLeaks, And More!</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#212121"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>
                            Access To The Most Power Stealth Engine: Samuari
                          </span>
                        </li>
                      </ul>
                      <div class="upgrade flex">
                        <button
                          type="button"
                          role="checkbox"
                          aria-checked="true"
                          data-state="checked"
                          value="on"
                        ></button>
                        <label>
                          Upgrade to our Stealth Samurai Engine for just
                          $4.99/month.
                        </label>
                      </div>
                      <div class="action-button">
                        <button type="button">Get Started</button>
                      </div>
                    </div>
                    <div class="plan boxed samesame">
                      <div class="plan-name text-white">Exclusive</div>
                      <div class="price">$299.99</div>
                      <div class="discounted-price">$37.49</div>
                      <div class="label">Billed Monthly</div>
                      <div class="excerpt">
                        For the professional who is already using AI to produce
                        a high volume of content, and needs peace of mind that
                        their writings wont be penalized for AI.
                        <div class="text-white">
                          Great for new creators, small businesses, and content
                          creators.
                        </div>
                      </div>
                      <div class="words-amount">1,000,000</div>
                      <div class="words-label">Words Per Month</div>
                      <ul class="check-list">
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Instant Response Time</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Beats Turnitin, GPTZero, And More!</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Access To The Infinity Stealth Engine</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>
                            Access To Proprietary Standard Stealth Engine
                          </span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#FFFFFF"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>
                            Process Up To 2,000 Words On The Infinity Engine
                          </span>
                        </li>
                      </ul>
                      <div class="divider"></div>
                      <ul class="un-check-list">
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#212121"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Increased Coherency On Infinity Engine</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#212121"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>Beats Winston AI, CopyLeaks, And More!</span>
                        </li>
                        <li>
                          <svg
                            stroke="currentColor"
                            fill="#212121"
                            stroke-width="0"
                            viewBox="0 0 512 512"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path>
                          </svg>
                          <span>
                            Access To The Most Power Stealth Engine: Samuari
                          </span>
                        </li>
                      </ul>
                      <div class="upgrade flex">
                        <button
                          type="button"
                          role="checkbox"
                          aria-checked="true"
                          data-state="checked"
                          value="on"
                        ></button>
                        <label>
                          Upgrade to our Stealth Samurai Engine for just
                          $4.99/month.
                        </label>
                      </div>
                      <div class="action-button">
                        <button type="button">Get Started</button>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Pricingsec;
