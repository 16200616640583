import React from "react";
import "./brands.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logoimg1 from "../assets/images/turnitin-logo.webp";
import Logoimg2 from "../assets/images/gpt-zero-logo.webp";
import Logoimg3 from "../assets/images/content-at-scale-logo.webp";
import Logoimg4 from "../assets/images/crossplag-logo.webp";
import Logoimg5 from "../assets/images/no-gpt-logo.webp";
import Logoimg6 from "../assets/images/aiseo-logo.webp";
import Logoimg7 from "../assets/images/writeful-logo.webp";
import Logoimg8 from "../assets/images/originality-logo.webp";
import Logoimg9 from "../assets/images/copyleaks-logo.webp";
import Logoimg10 from "../assets/images/winston-logo.webp";

function Brandsec() {
  return (
    <section className="brandssec">
      <Container>
        <Row>
          <Col>
            <h2 className="brandsh2">
              The <span>AI Detectors</span> We Beat
            </h2>
            <p>
              New AI Detectors are propping up all the time, and therefore
              StealthGPT is always under constant improvement. We understand
              what the AI Detectors are looking for and how they work. We built
              StealthGPT by reverse engineering the conventional wisdom and
              methods behind most AI Detectors, allowing us to create
              undetectable ai content. As we continue to test against AI
              Detectors this list will continue to grow.
            </p>
            <div className="featured-logos">
              <ul>
                <li>
                  <a className="invert" href="https://www.turnitin.com/">
                    <img alt="image" src={Logoimg1} />
                  </a>
                </li>
                <li>
                  <a className="invert" href="https://gptzero.me/">
                    <img alt="image" src={Logoimg2} />
                  </a>
                </li>
                <li>
                  <a className="invert" href="https://contentatscale.ai/">
                    <img alt="image" src={Logoimg3} />
                  </a>
                </li>
                <li>
                  <a className="invert" href="https://crossplag.com/">
                    <img alt="image" src={Logoimg4} />
                  </a>
                </li>
                <li>
                  <a className="invert" href="https://www.zerogpt.com/">
                    <img alt="image" src={Logoimg5} />
                  </a>
                </li>
                <li>
                  <a className="invert" href="https://aiseo.ai/">
                    <img alt="image" src={Logoimg6} />
                  </a>
                </li>
                <li>
                  <a className="invert" href="https://www.writefull.com/">
                    <img alt="image" src={Logoimg7} />
                  </a>
                </li>
                <li>
                  <a className="invert" href="https://originality.ai/">
                    <img alt="image" src={Logoimg8} />
                  </a>
                </li>
                <li>
                  <a className="invert" href="https://copyleaks.com/">
                    <img alt="image" src={Logoimg9} />
                  </a>
                </li>
                <li>
                  <a className="invert" href="https://gowinston.ai/">
                    <img alt="image" src={Logoimg10} />
                  </a>
                </li>
              </ul>
            </div>
            <div class="circle-bg"></div>
            <div className="graphbx">
              <h2 className="brandsh2 graphh2">
                Percentage of Undetected Responses
              </h2>
              <p>
                The following chart shows the percentage of responses that were
                detected as "Human-Written" by the top AI Detectors. The higher
                the percentage the better.
              </p>
              <div className="instuldiv">
                <ul className="instgraph">
                  <li
                    class="recharts-legend-item legend-item-0"
                  >
                    <svg
                      class="recharts-surface"
                      width="14"
                      height="14"
                      viewBox="0 0 32 32"
                    >
                      <title></title>
                      <desc></desc>
                      <path
                        stroke="none"
                        fill="#6366F1"
                        d="M0,4h32v24h-32z"
                        class="recharts-legend-icon"
                      ></path>
                    </svg>
                    <span
                      class="recharts-legend-item-text"
                    >
                      GPTZero
                    </span>
                  </li>
                  <li
                    class="recharts-legend-item legend-item-1"
                  >
                    <svg
                      class="recharts-surface"
                      width="14"
                      height="14"
                      viewBox="0 0 32 32"
                    >
                      <title></title>
                      <desc></desc>
                      <path
                        stroke="none"
                        fill="#1AFFD5"
                        d="M0,4h32v24h-32z"
                        class="recharts-legend-icon"
                      ></path>
                    </svg>
                    <span
                      class="recharts-legend-item-text"
                    >
                      OriginalityAI
                    </span>
                  </li>
                  <li
                    class="recharts-legend-item legend-item-2"
                  >
                    <svg
                      class="recharts-surface"
                      width="14"
                      height="14"
                      viewBox="0 0 32 32"
                    >
                      <title></title>
                      <desc></desc>
                      <path
                        stroke="none"
                        fill="#D4DCFF"
                        d="M0,4h32v24h-32z"
                        class="recharts-legend-icon"
                      ></path>
                    </svg>
                    <span
                      class="recharts-legend-item-text"
                    >
                      Copyleaks
                    </span>
                  </li>
                </ul>
              </div>
              <div className="bg-card graphbdiv">
                <ul className="percentgrapg">
                  <li>100%</li>
                  <li>75%</li>
                  <li>50%</li>
                  <li>25%</li>
                  <li>0%</li>
                </ul>
                <table
                  id="graphtable"
                  class="charts-css column multiple show-labels data-spacing-10 show-primary-axis show-data-axes reverse-datasets"
                >
                  <caption> Column Example #23 </caption>
                  <thead>
                    <tr>
                      <th scope="col"> Year </th>
                      <th scope="col"> Progress 1</th>
                      <th scope="col"> Progress 2</th>
                      <th scope="col"> Progress 3</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="graphtr tool-tip"  title="StealthGPT">
                      <th scope="row" className="tool-tip"  title="StealthGPT"> StealthGPT </th>
                      <td className="graphtd1"></td>
                      <td className="graphtd2"></td>
                      <td className="graphtd3"></td>
                    </tr>
                    <tr className="graphtr">
                      <th scope="row"> UndetectableAI </th>
                      <td className="graphtd4"></td>
                      <td className="graphtd5"></td>
                      <td className="graphtd6"></td>
                    </tr>
                    <tr className="graphtr">
                      <th scope="row"> StealthWriter </th>
                      <td className="graphtd7"></td>
                      <td className="graphtd8"></td>
                      <td className="graphtd9"></td>
                    </tr>
                    <tr className="graphtr">
                      <th scope="row"> ConchAI </th>
                      <td className="graphtd10"></td>
                      <td className="graphtd11"></td>
                      <td className="graphtd12"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="brandbtd">
              <a href="#" className="brandsbtn">
                Bypass Them Now
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Brandsec;
